import styled from 'styled-components';
import { isMobileBreakpoint } from 'ToolboxUtils/web/is-mobile-view';

const SecretProductCardWrapper = styled.div`
  background-color: ${props => props.isMobile ? 'white' : props.theme.color.tertiaryColor};
  background: ${props => props.alwaysWhite === true && 'white'};
  padding: 10px;
  display: flex;
  border-radius: ${props => props.roundedCorners === undefined || props.roundedCorners === true ? '8px': '0'};

  .slide-container {
    background: ${props => props.alwaysWhite === true && 'white'};
    display: grid;
    grid-template-columns: ${props => props.isMobile === true ? '30px 1fr 90px' : '30px 1fr 10px'};
    grid-template-columns: ${props => props.hideQuotes === true && '0 1fr 0'};
    grid-template-rows: ${props => props.isMobile === true ? `90px 1fr` : `auto 150px`}${props => props.hideUser !== true && ' 0 130px'};
    grid-column-gap: 10px;
    grid-template-areas: ${props =>
      `"${props.hideQuotes === true ? 'title' : 'left-quote'} title ${
        props.showSecretImage === true ? 'image' : 'title'
      }"
      "${
        props.hideQuotes === true ? 'description' : '.'
      } description description"
      ${props.hideQuotes === true ? '' : `". . right-quote"`}
      ${props.hideUser === true ? '' : `"user user user"`}
    `};

    @media (min-width: ${isMobileBreakpoint}px) {
      grid-template-areas: "left-quote title image"
          ". description image"
          ${props => `
            ${props.hideUser === true ? '' : `". right-quote ."`}
            ${props.hideUser === true ? '' : `"user user ."`} `}
          ;
    }
    width: 100%;
  }

  .slide-quote {
    font-size: 60px;
    line-height: 1;
  }

  .slide-opening-quote {
    grid-area: left-quote;
    justify-self: center;
    align-self: ${props => (props.isMobile === true ? 'center' : 'end')};
    line-height: ${props => (props.iMobile === true ? '1' : '0')};
  }

  .slide-title {
    grid-area: title;
    align-self: center;
    color: ${props => props.theme.color.primaryColor};
    font-size: ${props => props.isPublicProfile === true
        ? `18px`
        : `20px`};
    font-weight: bold;
    display: ${props => (props.productView === true ? 'block' : '-webkit-box')};
    -webkit-box-orient: vertical;
    -webkit-line-clamp: ${props => (props.isMobile === true ? '2' : '1')};
    overflow: hidden;
  }

  .slide-image {
    grid-area: image;
    background: #dddddd;
    box-shadow: inset 0 0 0 5px white;
    width: ${props => (props.isMobile === true ? '90px' : '140px')};
    height: ${props => (props.isMobile === true ? '90px' : '100px')};
    position: relative;
    .icon {
       position: absolute;
       top: 50%;
       left: 50%;
       transform: translate(-50%,-50%);
       font-size: 60px;
       color: white;
    }
    img {
      display: ${props => (props.secretImage === true ? 'block' : 'none')};
      position: relative;
      width:100%;
      height: 100%;
      object-fit: cover;
    }
    .magnify {
      position: absolute;
      bottom: -6px;
      left: 50%;
      transform: translateX(-50%);
      background-color: white;
      font-size: 29px;
      line-height: 1;
      padding: 5px 5px 0;
      mix-blend-mode: screen;
      border-radius: 4px;
      pointer: cursor;
      display: ${props => (props.secretImage === true ? 'block' : 'none')}
    }
  }

  .description-fade-out {
    grid-area: description;
    height: 10px;
    margin-left: ${props => (props.hideQuotes === true ? '' : '-10px')};
    position: relative;
    &.fade-out-top {
      background: ${props => props.isMobile === true
        ? 'linear-gradient(180deg, rgb(255,255,255), rgba(255,255,255,0))'
        : `linear-gradient(180deg, ${props.theme.color.tertiaryColor}, ${props.theme.color.tertiaryColor}00)`};
      background: ${props => props.alwaysWhite === true && 'linear-gradient(180deg, rgb(255,255,255), rgba(255,255,255,0))'};
    }
    &.fade-out-bottom {
      background: ${props => props.isMobile === true
        ? 'linear-gradient(0deg, rgb(255,255,255), rgba(255,255,255,0))'
        : `linear-gradient(0deg, ${props.theme.color.tertiaryColor}, ${props.theme.color.tertiaryColor}00)`};
      background: ${props =>   props.alwaysWhite === true && 'linear-gradient(0deg, rgb(255,255,255), rgba(255,255,255,0))'};
      top: 140px;
    }
  }

  .slide-description {
    font-size: ${props => props.isPublicProfile === true ? `13px` : ''};
    ${props => props.isPublicProfile === true
      ? `overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 7;
        -webkit-box-orient: vertical;`
      : `overflow-y: auto;`
    }
    scrollbar-width: none;
    grid-area: description;
    margin-left: ${props => (props.hideQuotes === true ? '' : '-10px')};
    padding: 10px 0;
    height: 150px;
    margin-bottom: ${props => props.hideUser === true && '20px'};
  }

  .slide-closing-quote {
    grid-area: right-quote;
    justify-self: end;
    margin-top: -10px;
    position: relative;
  }

  .slide-user {
    grid-area: ${props => props.hideUser !== true && 'user'};
    display: flex;
    align-items: center;
    font-size: 15px;
    height: 110px;
    align-self: end;

    .slide-user-image {
      width: 105px;
      height: 105px;
      position: relative;
      flex: 0 0 105px;

      img {
        display: ${props => (props.userImage === true ? 'block' : 'none')};
        object-fit: cover;
        width: 100%;
        height: 100%;
      }

      .slider-user-image-placeholder {
        position: absolute;
        top: 0;
        left: 0;
        background: ${props => props.theme.color.tertiaryColor};
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: 5px solid white;

        .icon {
          font-size: 90px;
        }
      }

      img {
        position: relative;
        z-index: 1;
        border: 5px solid white;
        border-radius: 50%;
      }
    }

      .slide-user-info {
        margin-left: 10px;
      }

    .slide-user-super-whisperer {
      font-weight: bold;
    }

    .slide-user-see-more {
      border-bottom: 1px solid ${props => props.theme.color.primaryColor}
      color: ${props => props.theme.color.primaryColor}
      width: fit-content;
      &:hover {
        text-decoration: none;
      }
    }
    .business {
      align-items: center;
      color: inherit;
      display: flex;
      margin-top: 5px;
      &:hover {
        text-decoration: none;
      }
      img {
        border-radius: 20px;
        height: 40px;
        margin-left: 5px;
        width: 40px;
      }
    }
  }


  @media (min-width: ${isMobileBreakpoint}px) {
    .slide-user {
      padding-top: 30px;
    }
  }
`;

export default SecretProductCardWrapper;
