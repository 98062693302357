import styled from 'styled-components';
import { isMobileBreakpoint } from 'ToolboxUtils/web/is-mobile-view';

const SecretHomeCardWrapper = styled.div`
  background: #3e3e3e;
  display: flex;
  color: white;
  padding: 10px;

  .anchor {
    display: flex;
    height: 100%;
  }

  .line-clamp-1 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .secret-home-card-image {
    height: 120px;
    width: 120px;
    object-fit: cover;
  }

  .secret-home-card-description {
    margin-left: 10px;
    display: flex;
    flex-flow: column;
  }

  .secret-home-card-title {
    font-family: ${props => props.theme.font.secondaryFont.name};
    font-weight: ${props =>
      props.isPublicProfile === true
        ? ''
        : parseInt(props.theme.font.secondaryFont.weight) + 300};
    font-size: ${props =>
      `${(20 * props.theme.font.secondaryFont.scaling) / 100}px`};
  }

  .secret-home-card-location {
    font-size: ${props =>
      `${(14 * props.theme.font.primaryFont.scaling) / 100}px`};
  }

  .secret-home-card-info {
    display: flex;
    justify-content: space-between;
    font-size: ${props =>
      `${(12 * props.theme.font.primaryFont.scaling) / 100}px`};
    margin-top: auto;

    .secret-home-card-time {
      .live-editor-wrapper {
        display: inline-block;
      }
      .is-on {
        color: #25e250;
      }

      is-off {
        color: red;
      }
    }

    .icon {
      font-size: 17px;
      position: relative;
      top: 4px;
    }
  }

  .secret-home-card-distance {
    margin-right: 10px;
    font-size: ${props =>
      props.isPublicProfile === true
        ? `${(15 * props.theme.font.secondaryFont.scaling) / 100}px`
        : `${(16 * props.theme.font.secondaryFont.scaling) / 100}px`};

    .icon {
      margin-right: 5px;
    }
  }

  @media (min-width: ${props =>
      props.imageTop === true ? '0' : isMobileBreakpoint}px) {
    padding: 0;
    flex: 1;
    order: -1;
    flex-flow: column;
    justify-content: space-between;
    margin-left: 0;

    .anchor {
      flex-flow: column;
    }

    .secret-home-card-image {
      height: 160px;
      width: 100%;
      flex-grow: 2;
    }

    .secret-home-card-description {
      flex-grow: 1;
      padding: 20px 10px;
    }

    .secret-home-card-title {
      font-size: ${props =>
        props.isPublicProfile === true
          ? ''
          : `${(20 * props.theme.font.secondaryFont.scaling) / 100}px`};
    }

    .secret-home-card-location {
      font-size: ${props =>
        props.isPublicProfile === true
          ? ''
          : `${(18 * props.theme.font.primaryFont.scaling) / 100}px`};
    }

    .secret-home-card-info {
      justify-content: flex-start;
      font-size: ${props =>
        `${(18 * props.theme.font.primaryFont.scaling) / 100}px`};

      .secret-home-card-time {
        margin-left: 10px;
        font-size: ${props =>
          props.isPublicProfile === true
            ? `${(15 * props.theme.font.secondaryFont.scaling) / 100}px`
            : `${(16 * props.theme.font.secondaryFont.scaling) / 100}px`};
      }

      .icon {
        font-size: ${props =>
          props.isPublicProfile === true ? '28px' : '32px'};
        top: 7px;
      }
    }
  }
`;

export default SecretHomeCardWrapper;
