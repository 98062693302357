import React from 'react';
import PropTypes from 'prop-types';
import Anchor from 'ToolboxComponents/commons/anchors/anchor/anchor';

const MyLink = ({ children, className, isAnchor, url, ...rest }) => {
  if (isAnchor) {
      return <Anchor className={className} {...rest}>{children}</Anchor>
  } else if (url) {
      return <a className={className} href={url}>{children}</a>
  } else {
      return <div className={className}>{children}</div>;
  }
};

MyLink.propTypes = {
  isAnchor: PropTypes.bool
};

export default MyLink;