import React, { useState, useEffect, useContext } from 'react';
import { ThemeContext } from 'ToolboxUtils/web/context/context';
import formatSchedule from 'ToolboxUtils/web/formats/format-schedule';
import { LanguageContext } from 'ToolboxUtils/web/context/context';
import Text from 'ToolboxComponents/commons/texts/text/text';
import ImageResize from 'ToolboxComponents/utils/images/image-resize/image-resize';
import PropTypes from 'prop-types';
import DistanceConverted from 'ToolboxComponents/utils/formats/distances/distance';
import SecretHomeCardWrapper from 'ToolboxComponents/commons/cards/secret-home-card/secret-home-card.style';
import Anchor from 'ToolboxComponents/commons/anchors/anchor/anchor';
import slugify from 'url-slug';

const SecretHomeCard = props => {
  const theme = useContext(ThemeContext).state;
  const { secret } = props;
  const [language] = useContext(LanguageContext).usePath();
  const [scheduleStatus, setScheduleStatus] = useState('');
  const [schedulePath, setSchedulePath] = useState(null);
  const [scheduleHour, setScheduleHour] = useState({});

  useEffect(() => {
    const schedule = formatSchedule(
      false, {
      startTime: secret.productStartTime,
      endTime: secret.productEndTime,
      language,
    });

    setScheduleStatus(schedule.status);
    setSchedulePath(schedule.message);
    setScheduleHour(schedule.hour);
    // eslint-disable-next-line
  }, [secret]);
  return (
    <SecretHomeCardWrapper
      theme={theme}
      className="secret-home-card"
      {...props}
    >
      <Anchor
        className={`anchor`}
        code="product"
        onClick={() => {props.onClick && props.onClick()}}
        pageParams={`/${secret.productId}/${slugify(secret.productTitle, {lower: true})}`}
      >
        <ImageResize
          className={`secret-home-card-image`}
          src={secret.photo !== null ? secret.photo : secret.productPhoto}
        />
        <div className={`secret-home-card-description`}>
          <div className={`secret-home-card-title line-clamp-1`}>
            {secret.productTitle}
          </div>
          <div className={`secret-home-card-location line-clamp-1`}>
            {secret.productCity}
          </div>
          <div className={`secret-home-card-info`}>
            <div className={`secret-home-card-distance`}>
              <span className="adn adn-directions icon" />
              <DistanceConverted distance={secret.distance} />
            </div>
            {schedulePath !== null && (
              <div className={`secret-home-card-time`}>
                <span className="adn adn-clock-outline icon" />{' '}
                <span className={`live-editor-wrapper`}>
                  <Text
                    path={schedulePath}
                    data={{ time: scheduleHour }}
                    className={`is-${scheduleStatus}`}
                  />
                </span>
              </div>
            )}
          </div>
        </div>
      </Anchor>
    </SecretHomeCardWrapper>
  );
};

SecretHomeCard.defaultProps = {
  imageTop: false,
};

SecretHomeCard.propTypes = {
  secret: PropTypes.object,
  imageTop: PropTypes.bool,
};

export default SecretHomeCard;
