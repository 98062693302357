import React, { useState, useEffect, useContext } from 'react';
import { ThemeContext } from 'ToolboxUtils/web/context/context';
import isMobileView from 'ToolboxUtils/web/is-mobile-view';
import SecretProductCardWrapper from './secret-product-card.style';
import ImageResize from 'ToolboxComponents/utils/images/image-resize/image-resize';
import Text from 'ToolboxComponents/commons/texts/text/text';
import Anchor from 'ToolboxComponents/commons/anchors/anchor/anchor';
import MyLink from './components/my-link';
import slugify from 'url-slug';

const SecretProductCard = props => {
  const theme = useContext(ThemeContext).state;

  const [isMobile, setIsMobile] = useState(null);
  useEffect(() => {
    setIsMobile(isMobileView());
  }, [isMobile]);

  const [secretImage, setSecretImage] = useState(true);
  const [userImage, setUserImage] = useState(true);
  const {
    secret: {
      title,
      description,
      photo,
      productId,
      accountPhoto,
      accountId,
      accountName,
      accountSecretCount,
      linkToProduct,
      productView,
      businessIcon,
      businessLink,
      businessTitle,
      businessLogo,
    },
    isPublicProfile,
    isWidget,
    getAccountUrl
  } = props;

  useEffect(() => {
    if (photo === null) {
      setSecretImage(false);
    } else {
      setSecretImage(true);
    }

    if (accountPhoto === null) {
      setUserImage(false);
    } else {
      setUserImage(true);
    }
  }, [accountPhoto, photo]);

  return (
    <SecretProductCardWrapper
      secretImage={secretImage}
      userImage={userImage}
      isMobile={isMobile}
      theme={theme}
      className={`secret-product-card`}
      {...props}
    >
      <div className={`slide-container ${title === null ? 'no-title' : ''}`}>
        {props.hideQuotes !== true && (
          <div className="slide-opening-quote slide-quote">&ldquo;</div>
        )}
        {!!linkToProduct && !isWidget ? (
          <Anchor
            className={`slide-title`}
            code="product"
            pageParams={`/${productId}/${slugify(title, {lower: true})}`}
          >
            {title}
          </Anchor>
        ) : (
          <div className={`slide-title`}>{title}</div>
        )}
        <div className="description-fade-out fade-out-top"></div>
        <p className="slide-description">{description}</p>
        <div className="description-fade-out fade-out-bottom"></div>
        {props.hideQuotes !== true && (
          <div className="slide-closing-quote slide-quote">&rdquo;</div>
        )}
        {props.hideUser !== true && (
          <div className="slide-user">
            <MyLink
              isAnchor={!isWidget}
              code="public"
              pageParams={`/${accountId}`}
              className="slide-user-image"
              url={getAccountUrl && getAccountUrl(accountId)}
            >
              <ImageResize src={accountPhoto} />
              <div className="slider-user-image-placeholder">
                <span className="adn adn-account icon"></span>
              </div>
            </MyLink>
            <div className="slide-user-info">
              <MyLink isAnchor={!isWidget} code="public" pageParams={`/${accountId}`} url={getAccountUrl && getAccountUrl(accountId)}>
                <div className="slide-user-name">
                  <Text
                    path={`page.homepage.secretSliderWhispererName`}
                    data={{
                      name: accountName,
                    }}
                  />
                </div>
                {accountSecretCount > 4 && (
                  <div className='slide-user-see-more'>
                    <Text path='page.homepage.secretSliderWhispererStatus' /> | {`${accountSecretCount} secrets`}
                  </div>
                )}
                {accountSecretCount <= 4 && accountSecretCount > 1 && (
                  <span className='slide-user-see-more'>
                    <Text
                      path='page.homepage.secretSliderWhispererSecrets'
                      data={{
                        ownPlural: accountSecretCount > 1 ? 'es' : 'on',
                        secretsCount: accountSecretCount,
                        secretPlural: accountSecretCount > 1 ? 's' : '',
                      }}
                    />
                  </span>
                )}
              </MyLink>
              {businessLink !== null && !isWidget &&
                <a className='business' href={businessLink} target='_blank'>
                  {businessTitle}
                  <img src={businessLogo} />
                </a>
              }
            </div>
          </div>
        )}
      </div>
    </SecretProductCardWrapper>
  );
};

export default SecretProductCard;
